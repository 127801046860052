@use "../../../../styles/variables";
@use "../../../../styles/mixin";

.modalWrapper {
  padding: 32px 16px 16px;
  background: url("/images/confetti.webp") no-repeat top/contain, linear-gradient(180deg, #ebf6ff 0%, #cfe6f9 100%);

  @include mixin.devices(tablet) {
    padding: 55px 88px 24px;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.modalTitle {
  margin: 0;

  color: variables.$main-text-color;
  font-family: variables.$fontMontserrat;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.21;
  text-align: center;

  @include mixin.devices(tablet) {
    font-size: 36px;
  }

  @include mixin.devices(desktop-plus) {
    font-size: 40px;
  }
}

.appWrapper {
  display: flex;
  gap: 8px;

  @include mixin.devices(tablet) {
    gap: 30px;
  }
}

.modalDesc {
  margin: 0;

  color: variables.$gray-color;
  font-family: variables.$fontMontserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0px;
  text-align: center;
  max-width: 298px;

  @include mixin.devices(tablet) {
    font-size: 16px;
  }
}

.metricsWrapper {
  display: flex;
  flex-direction: column;
  background: variables.$white;
  list-style: none;
  gap: 16px;

  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px #3c85bf2e;

  width: 256px;

  @include mixin.devices(phone-tablet) {
    width: 298px;
  }

  @include mixin.devices(tablet) {
    width: 410px;
    margin: 30px 0;
    padding: 20px 16px;
    border-radius: 15px;
  }
}

.metricsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metricsItemTextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.metricsItemImg {
  width: 18px;
  height: 18px;

  @include mixin.devices(tablet) {
    width: 22px;
    height: 22px;
  }
}

.metricsItemText {
  color: variables.$text-gray-color;
  font-family: variables.$fontMontserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.21;
  letter-spacing: 0px;
}

.newGameBtn {
  width: 100%;
  height: 50px;
  background: variables.$button-gradient-color;
  border-radius: 12px;
  color: variables.$white;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.25;
  border: 0;
  outline: 0;
  font-family: variables.$fontMontserrat;
  margin: 0 auto;
  display: block;
  filter: drop-shadow(0px 11px 22px rgba(44, 131, 200, 0.26));

  @include mixin.devices(tablet) {
    width: 300px;
    height: 60px;
  }
}

.awardsProgressThumb {
  margin: 0 auto 16px;

  width: 250px;
  height: 6px;

  background-color: variables.$secondary-color;
  border-radius: 6px;
  overflow: hidden;

  @include mixin.devices(tablet) {
    margin: 0 auto 23px;
  }
}

.awardsProgress {
  height: 100%;
  background-color: variables.$main-color;
  transition: width 0.3s ease-in-out;
}
